import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dotd-forth-street',
  templateUrl: './dotd-forth-street.component.html',
  styleUrls: ['./dotd-forth-street.component.scss'],
})
export class DotdForthStreetComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
