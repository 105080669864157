import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-merchant-program",
  templateUrl: "./merchant-program.component.html",
  styleUrls: ["./merchant-program.component.scss"],
})
export class MerchantProgramComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit() {}
  goHome() {
    this.router.navigate(["/home"]);
  }
}
