import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { BrandsComponent } from "./home/Pages/brands/brands.component";
import { DotdForthStreetComponent } from "./home/Pages/campaigns/forth-street/dotd-forth-street/dotd-forth-street.component";
import { HuntersVfbComponent } from "./home/Pages/campaigns/hunters/hunters-vfb/hunters-vfb.component";
import { SavannaDotdComponent } from "./home/Pages/campaigns/savanna/savanna-dotd/savanna-dotd.component";
import { SavannaComponent } from "./home/Pages/campaigns/savanna/savanna.component";
import { ScratchGameComponent } from "./home/Pages/campaigns/scratch-game/scratch-game.component";
import { ViceroyDotdComponent } from "./home/Pages/campaigns/viceroy/viceroy-dotd/viceroy-dotd.component";
import { ConditionsComponent } from "./home/Pages/conditions/conditions.component";
import { LocationsComponent } from "./home/Pages/locations/locations.component";
import { MerchantProgramComponent } from "./home/Pages/merchant-program/merchant-program.component";
import { TermsComponent } from "./home/Pages/terms/terms.component";

const routes: Routes = [
  {
    path: "home",
    loadChildren: () =>
      import("./home/home.module").then((m) => m.HomePageModule),
  },
  {
    path: "",
    redirectTo: "home",
    pathMatch: "full",
  },
  {
    path: "brands",
    component: BrandsComponent,
    pathMatch: "full",
  },
  {
    path: "merchants",
    component: MerchantProgramComponent,
    pathMatch: "full",
  },
  {
    path: "locations",
    component: LocationsComponent,
    pathMatch: "full",
  },
  // {
  //   path: "savanna-promo",
  //   component: SavannaComponent,
  //   pathMatch: "full",
  // },
  // {
  //   path: "savanna-dotd",
  //   component: SavannaDotdComponent,
  //   pathMatch: "full",
  // },
  {
    path: "viceroy-dotd",
    component: ViceroyDotdComponent,
    pathMatch: "full",
  },
  {
    path: "street",
    component: DotdForthStreetComponent,
    pathMatch: "full",
  },
  {
    path: "scratch",
    component: ScratchGameComponent,
    pathMatch: "full",
  },
  // {
  //   path: "conditions",
  //   component: ConditionsComponent,
  //   pathMatch: "full",
  // },
  // {
  //   path: "hunters-view-bucks",
  //   component: HuntersVfbComponent,
  //   pathMatch: "full",
  // },
  // {
  //   path: "conditions",
  //   component: ConditionsComponent,
  //   pathMatch: "full",
  // },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
