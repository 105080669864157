import { Injectable, ɵConsole } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { GameModel } from "../models/game-model";
import { GameCompletePostModel } from "../models/game-complete-post-model";
import { catchError, retry } from "rxjs/operators";
import { Observable, throwError } from "rxjs";
import { InteractionModel } from "../models/interactionModel";

@Injectable({
  providedIn: "root",
})
export class NextServiceService {
  constructor(private http: HttpClient) {}
  transformJsonBodyToUrlEncoded(obj) {
    var str = [];
    for (var p in obj)
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    return str.join("&");
  }

  // mainUrl = "http://cxpprd1.dedicated.co.za/NexService-3.0/";
  mainUrl = "http://cxp.dedicated.co.za/NexService-3.0/";

  async createInteractionLog(body: InteractionModel) {
    const path = "createinteractionlog";
    var response = await this.http
      .post(this.mainUrl + path, body)
      .pipe(retry(1), catchError(this.errorHandl))
      .toPromise();
    if (response["status"] == 200) {
      return true;
    } else {
      return false;
    }
  }

  async getScratchGame(idToken, brandUid) {
    console.log("Id Token");
    console.log(idToken);

    console.log("brandUid");
    console.log(brandUid);
    const path = "getgames";
    var body = {
      idToken: idToken,
      brandUid: brandUid,
    };
    var response: GameModel[] = await this.http
      .post<GameModel[]>(this.mainUrl + path, body)
      .pipe(retry(1), catchError(this.errorHandl))
      .toPromise();
    console.log("RESPONSE TITLE");
    console.log(response);
    return response;
  }

  async playedGameCall(body: GameCompletePostModel) {
    const path = "playedgame";

    console.log("URL PATH");
    console.log(this.mainUrl + path);

    var response = await this.http
      .post(this.mainUrl + path, body)
      .pipe(retry(1), catchError(this.errorHandl))
      .toPromise();

    console.log("RESPONSE");
    console.log(JSON.stringify(response));

    if (response["status"] == 200) {
      return true;
    } else {
      return false;
    }
  }

  // Error handling
  errorHandl(error): Observable<any> {
    let errorMessage = "";
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }
}
