import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-brands",
  templateUrl: "./brands.component.html",
  styleUrls: ["./brands.component.scss"],
})
export class BrandsComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit() {}

  goHome() {
    this.router.navigate(["home"]);
  }
  openAccord(event) {
    const current = event.currentTarget;
    current.classList.toggle("list__item--open");

    const siblings = document.querySelectorAll(".list__item");
    siblings.forEach((el: any) => {
      if (el != current) {
        el.classList.remove("list__item--open");
        el.querySelector("Input").checked = false;
      }
    });
    const checkbox = current.querySelector("Input");
    checkbox.checked = !checkbox.checked;
    // array.forEach((element) => {});
  }
}
