export function quickSortBasic(array, key) {
  if (array.length < 2) {
    return array;
  }

  var pivot = array[0];
  var lesserArray = [];
  var greaterArray = [];

  for (var i = 1; i < array.length; i++) {
    if (array[i][key] > pivot[key]) {
      greaterArray.push(array[i]);
    } else {
      lesserArray.push(array[i]);
    }
  }

  return quickSortBasic(lesserArray, key).concat(
    pivot,
    quickSortBasic(greaterArray, key)
  );
}

export function getCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

export function isTimeBetween(start: number, end: number) {
  const currentTime = new Date().getHours();
  return currentTime >= start && currentTime <= end;
}

export function isWednesday(): boolean {
  return isDay(3)();
}

export function isDay(dayNumber: number) {
  return () => new Date().getDay() === dayNumber;
}
