import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-viceroy-dotd",
  templateUrl: "./viceroy-dotd.component.html",
  styleUrls: ["./viceroy-dotd.component.scss"],
})
export class ViceroyDotdComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit() {}

  goHome() {
    this.router.navigate(["home"]);
  }

  routeToWallet() {
    window["navigateToWallet"]();
  }
}
