import { isTimeBetween, quickSortBasic } from "src/helpers";
import { Tile } from "../../models/tile";

const tilesData = [
  {
    id: "scratch",
    name: "",
    offer: "",
    img: "assets/tiles/scratch_tile.jpg",
    color: "#015319",
    cta: "TAP TO PLAY",
    active: true,
  },
  {
    id: "hunters-view-bucks",
    name: "View video",
    offer: "GET 50 BUCKS",
    img: "assets/tiles/hunters_tile.jpg",
    active: true,
    cta: "ACTIVE",
  },
  {
    id: "savanna-promo",
    name: "View video",
    offer: "GET 50 BUCKS",
    img: "assets/tiles/savanna_tile.png",
    color: "#015319",
    route: "savanna-promo",
    cta: "ACTIVE",
    active: true,
  },
  {
    id: "viceroy-dotd",
    name: "",
    offer: `EARN\nR10 AIRTIME\nVOUCHER`,
    img: "assets/tiles/viceroy_tile.jpg",
    cta: new Date().getDay() == 5 ? "ACTIVE" : "ACTIVE SOON",
    active: new Date().getDay() == 5,
  },
  {
    id: "app-dotd-forth-street",
    name: "",
    offer: "EARN\nDOUBLE BUCKS",
    img: "assets/tiles/4thstreet_tile.jpg",
    active: new Date().getDay() == 5,
    cta: new Date().getDay() == 5 ? "ACTIVE" : "ACTIVE SOON",
  },
  // SAVANNA
  {
    id: "savanna-dotd",
    name: "Savanna",
    offer: "DISCOUNT OFFER",
    img: "assets/tiles/savanna_tile.png",
    color: "#015319",
    active: new Date().getDay() == 6,
    cta: new Date().getDay() == 6 ? "ACTIVE" : "ACTIVE SOON",
  },
].filter((t) => t.active);

export { tilesData };
