import { BehaviorSubject } from "rxjs";
import { GameModel } from "../models/game-model";
import { Injectable } from "@angular/core";
import { Wallet } from "../models/wallet";
import { UserObject } from "../models/user-object";
import { Tile } from "../models/tile";

@Injectable({
  providedIn: "root", // <--provides this service in the root ModuleInjector
})
export class StorageService {
  public ScratchGame = new BehaviorSubject<GameModel>(null);
  public hurryCampaign = new BehaviorSubject<GameModel>(null);
  public idToken = new BehaviorSubject<string>(null);
  public walletBalance = new BehaviorSubject<Wallet>(null);
  public userObject = new BehaviorSubject<UserObject>(null);
  public currentTile = new BehaviorSubject<any>(null);
  public homeLink = new BehaviorSubject<string>(null);
}
