import { PrizeModel } from './prize-model';

export class GameModel {
    title:string;
    description:string;
    type:string;
    prize:PrizeModel[];
    brandUid:string;
    createdOn:string;
    modifiedOn?:string;
    nextPlay:Date;
    status:number;
    canPlay:boolean;
    uid:string;
}
