import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NgDialogAnimationService } from "ng-dialog-animation";
import { NewScratchGameComponent } from "src/app/home/Dialogs/new-scratch-game/new-scratch-game.component";
import { GameModel } from "src/app/home/models/game-model";
import { UserObject } from "src/app/home/models/user-object";
import { Wallet } from "src/app/home/models/wallet";
import { NextServiceService } from "src/app/home/Services/next-service.service";
import { StorageService } from "src/app/home/Services/storageService";
import { tilesData } from "../../landing-page/data";

@Component({
  selector: "app-scratch-game",
  templateUrl: "./scratch-game.component.html",
  styleUrls: ["./scratch-game.component.scss"],
})
export class ScratchGameComponent implements OnInit {
  slideOpts = {
    initialSlide: 3,
    speed: 400,
  };
  interVal;
  userDetailsInterVal;
  gotHeader: boolean = false;
  games: GameModel[];
  scratchGame: GameModel = new GameModel();
  hurryCampaign: GameModel = new GameModel();
  idToken;

  public redeemDisabled: boolean = false;
  public redeemHidden: boolean = false;

  //User state vars
  firstname: string;
  walletBalance: number = null;

  //Campaigns
  tiles = tilesData;
  activeTile: string = "";

  public campaigns: any[] = null;
  public current = null;

  constructor(
    private dialog: NgDialogAnimationService,
    private nextServices: NextServiceService,
    private storageService: StorageService,
    private router: Router
  ) {}

  ngOnInit() {
    this.storageService.ScratchGame.subscribe((scratchGame) => {
      if (scratchGame != null) {
        this.scratchGame = scratchGame;
      }
    });

    this.storageService.hurryCampaign.subscribe((hurryC) => {
      if (hurryC != null) {
        this.hurryCampaign = hurryC;
      }
      console.log("CAN PLAY HURRY HOME");
      console.log(this.hurryCampaign.canPlay);
    });

    //Subcribe to User Data

    this.storageService.userObject.subscribe((user) => {
      if (user != null) {
        this.firstname = user.name;
      }
    });
    this.storageService.walletBalance.subscribe((wallet) => {
      if (wallet != null) {
        this.walletBalance = wallet.balance;
      }
    });

    this.storageService.idToken.subscribe((token) => {
      if (token != null) {
        this.idToken = token;
      }
    });

    // TODO: Comment this line for dev
    // this.callForIdToken();
    this.getTokenFromWebsite();

    this.getUserFromWebsite();

    //
    setTimeout(() => {
      this.navigateToComp();
    }, 1000);
  }

  callForIdToken() {
    window["askForTokenFunction"]();
  }

  ///On click handler for earn bucks
  handleEarnBucks() {
    // window["navigateToWallet"]();
  }

  ///On click handler for burn bucks
  handleBurnBucks() {
    window["navigateToWalletPage"]();
  }

  async getTokenFromWebsite() {
    var testValue = await this.getToken();
    this.games = await this.nextServices.getScratchGame(
      testValue[1],
      testValue[0]
    );

    this.idToken = testValue[1];
    this.storageService.idToken.next(testValue[1]);

    console.log("API RESPONSE");
    console.log(JSON.stringify(this.games));

    for (var item in this.games) {
      console.log("TYPE");
      console.log(this.games[item].type);
      if (
        this.games[item].type == "scratch" &&
        this.games[item].brandUid == "bevdash"
      ) {
        console.log("IM HITTING SCRATCH");
        this.storageService.ScratchGame.next(this.games[item]);
      } else if (this.games[item].type == "hurry") {
        console.log("IM HITTING HURRY");
        this.storageService.hurryCampaign.next(this.games[item]);
      }
    }
  }

  /**  */
  // handleRedeem() {
  //   this.nextServices.createInteractionLog({
  //     deviceUuid: "_",
  //     interactionUuid: "",
  //     type: "",
  //   });
  //   window["navigateToCoupon"]();
  // }

  async getToken() {
    localStorage.removeItem("idToken");
    localStorage.removeItem("brandUid");

    window["askForTokenFunction"]();
    var promise = new Promise((resolve, reject) => {
      this.interVal = setInterval(() => this.getIdToken(resolve, reject), 1000);
    });
    return promise;
  }

  getIdToken(resolve, reject) {
    const getIdToken = localStorage.getItem("idToken");
    const brandUid = localStorage.getItem("brandUid");
    if (getIdToken != null) {
      console.log("GOT THE TOKEN");
      resolve([brandUid, getIdToken]);
      clearTimeout(this.interVal);
    }
  }

  //Start User Fetch

  async getUserFromWebsite() {
    const profile = await this.getUserDetailsFn();

    console.log("profile should be here");
    console.log(profile);

    var userObject: UserObject = new UserObject();
    userObject.name = `${profile[0]}`;

    var userWallet: Wallet = new Wallet();
    userWallet.balance = parseInt(profile[1]);

    this.storageService.userObject.next(userObject);
    this.storageService.walletBalance.next(userWallet);
  }

  async getUserDetailsFn() {
    localStorage.removeItem("firstName");
    localStorage.removeItem("walletBalance");

    console.log("Inside getUserFn");

    var promise = new Promise((resolve, reject) => {
      this.userDetailsInterVal = setInterval(
        () => this.getUserDetails(resolve, reject),
        1000
      );
    });
    return promise;
  }

  getUserDetails(resolve, reject) {
    const firstName = localStorage.getItem("firstName");
    const walletBalance = localStorage.getItem("walletBalance");

    if (firstName != null && walletBalance != null) {
      resolve([firstName, walletBalance]);
      clearTimeout(this.userDetailsInterVal);
    }
  }

  //End User Fetch

  navigateToComp() {
    console.log("Yeah we are navigating");
    const dialogRef = this.dialog.open(NewScratchGameComponent, {
      height: "100%",
      width: "100vw",
      animation: { to: "top" },
      data: this.games[0],
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }

  showMapsIntent() {
    window["showMapsIntent"]();
  }

  navigateToBrands() {
    console.log("Going to brands");
    this.router.navigate(["brands"]);
  }
  navigateToMerchants() {
    this.router.navigate(["merchants"]);
  }
  navigateToLocations() {
    this.router.navigate(["locations"]);
  }
  navigateToHome() {
    this.router.navigate(["home"]);
  }

  //Campaigns
  closeDialog() {
    this.current = null;
  }

  openCampaign(id, purge = false) {
    //Reset possibly hidden redeem button
    this.redeemHidden = false;

    if (purge) {
      this.current = null;
    }
    const activeCampaign = this.tiles.find((c) => c.id === id);

    this.storageService.currentTile.next(activeCampaign);

    if (id == "scratch") {
      this.redeemHidden = true;
      // this.navigateToComp();
      // return;
    }

    console.log({ id });

    this.current = activeCampaign;
  }

  async handleCampaignAction(actionId: string) {
    this.redeemDisabled = true;

    const interactionData = {
      interactionUuid: "",
      deviceUuid: "_",
      type: "CLICK",
      idToken: this.idToken,
    };
    switch (actionId) {
      case "scratch": {
        break;
      }
      case "ladies": {
        console.log("Inside ladies night");
        interactionData.interactionUuid = "ladies_lunch_011_click";
        break;
      }
      case "tuesday": {
        console.log("Inside tuesday");
        interactionData.interactionUuid = "steak_tuesday_011_click";
        break;
      }
      default:
        break;
    }

    console.log({ actionId, interactionData });
    await this.nextServices.createInteractionLog(interactionData);

    window["navigateToCoupon"]();
  }
}
